import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const HardscapeLandscapePage = () => {
  return (
    <Layout>
      <Seo
        title={"Financing for Hardscape Contractors"}
        description={
          "As a hardscape contractor, offering flexible payment options is one of the best ways to create a competitive advantage in the home improvement space."
        }
      />
      <Helmet>
        <script type="application/ld+json" async={true}>
          {`{
 "@context": "https://schema.org/",
 "@type": "Product",
 "name": "Financing for Hardscape Contractors",
 "image": "",
 "description": "As a hardscape contractor, offering flexible payment options is one of the best ways to create a competitive advantage in the home improvement space."
 }`}
        </script>
      </Helmet>
      <section
        className="page-hero"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/hardscape-landscape-bg1-scaled.jpg" +
            ")",
          backgroundPosition: "50% 25%",
        }}
      >
        <div className="page-hero-inner d-flex justify-content-center align-items-center">
          <div className="container text-center">
            <h1 className="text-white">Financing for Hardscape Contractors</h1>
          </div>
        </div>
      </section>
      <section className="section-gap bg-light-gray">
        <div className="container">
          <div className="justify-content-center">
            <p>
              When customers want to invest in a hardscaping project, they often
              look for financing options to make the cost more manageable. As a
              hardscape contractor, offering flexible payment options is one of
              the best ways to create a competitive advantage in the home
              improvement space.
            </p>
            <p className="mt-2">
              Regions | EnerBank offers attractive{" "}
              <Link className="text-blue" to="/contractor-financing/">
                contractor financing
              </Link>{" "}
              options that can help you boost sales, build a competitive edge,
              and improve customer satisfaction.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-light-gray py-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              The Benefits of Hardscape and Landscape Financing
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5 financing-x2-images industry">
            <div className="col-12">
              <p className="font-weight-bold">
                Aside from giving your customers more options, contractor
                financing also benefits your business. Below are some of the key
                benefits for landscape and hardscape contractors:
              </p>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-image ">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/on-point.png"
                    alt="competitive edge"
                  />
                </div>
                <div className="card-body">
                  <h3 className="text-center">
                    Build a <br />
                    Competitive Edge
                  </h3>
                  <p className="card-text">
                    Offering financing options sets you apart from the
                    competition. According to industry research, 76% of
                    consumers are more likely to purchase if a simple and
                    seamless payment plan is offered*. EnerBank ensures customer
                    convenience by offering paperless application methods via
                    our website, mobile loan app, and loan-by-phone service.
                  </p>
                  <p className="mt-2 font-italic">
                    *Based on the Brickyard Study commissioned by EnerBank USA,
                    2018.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-image ">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/customer-relationship.png"
                    alt="customer retention"
                  />
                </div>
                <div className="card-body">
                  <h3 className="text-center">
                    Improve Customer <br />
                    Retention
                  </h3>
                  <p>
                    Offering financing options to customers helps them manage
                    their cash flow better and creates a stronger connection
                    with your clients, thereby improving customer retention.
                    Once they understand the benefits of the payment options you
                    offer, they’re more likely to return to your business the
                    next time they plan a project. EnerBank provides hardscape
                    contractors that work with us a full suite of marketing
                    tools and collateral to help them better explain these
                    advantages to their clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-image ">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/increase.png"
                    alt="increase sales"
                  />
                </div>
                <div className="card-body">
                  <h3 className="text-center">
                    Increase Sales & Boost <br />
                    Average Order Value*
                  </h3>
                  <p>
                    Financing for landscape contractors helps your business
                    close more deals by allowing more customers to utilize your
                    service. They also serve as an effective upselling tool.
                    Increase the size of your transactions by showing your
                    clients how a small increase in their monthly payments can
                    help them get the upgrades they want. More sales and larger
                    purchases increase your bottom line.
                  </p>
                  <p className="mt-2 font-italic">
                    *Based on the Brickyard Study commissioned by EnerBank USA,
                    2018
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-image ">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/growth.png"
                    alt="grow customer base"
                  />
                </div>
                <div className="card-body">
                  <h3 className="text-center">
                    Grow Your <br />
                    Customer Base
                  </h3>
                  <p>
                    Not every homeowner has enough cash to fund their dream
                    hardscape project upfront. By offering financing options,
                    you make your services more accessible to a larger number of
                    clients, helping you grow your customer base. Moreover, as
                    you improve customer satisfaction through accessible payment
                    plans, you also earn more referrals from existing clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light-gray py-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              How to Offer Financing as a Contractor
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p>
                Hardscape contractors can rely on EnerBank for specialized loan
                programs that boost sales and set them apart from the
                competition. With over two decades of providing financing
                services in the home improvement industry, we have solutions to
                meet your unique needs.
              </p>
              <p className="mt-2">
                Our financing application process is seamless and simple.
                Customers can apply through our three paperless methods: via
                mobile app, online application, and loan-by-phone. Funds are
                deposited within 24 hours upon client approval.
              </p>
            </div>
          </div>
          <ul className="row landscape-channels py-3">
            <li className="col-md-4 mb-3 mb-md-0">
              <div className="landscape-channel-icon">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/phone.png"
                  alt="phone"
                />
              </div>
              <h3 className="text-center font-weight-bold">Phone</h3>
            </li>
            <li className="col-md-4 mb-3 mb-md-0">
              <div className="landscape-channel-icon">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/online.png"
                  alt="online"
                />
              </div>
              <h3 className="text-center font-weight-bold">Online</h3>
            </li>
            <li className="col-md-4 mb-3 mb-md-0">
              <div className="landscape-channel-icon">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/02/mobile.png"
                  alt="mobile app"
                />
              </div>
              <h3 className="text-center font-weight-bold">Mobile App</h3>
            </li>
          </ul>
        </div>
      </section>

      <section className="bg-light-gray py-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">Why Choose EnerBank?</h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-2">
              <p className="font-weight-bold">
                At EnerBank, we’re dedicated to providing contractors like you
                with stellar customer service. When you choose EnerBank as your
                contractor financing provider, you enjoy these unique
                advantages:
              </p>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <ul>
                <li className="landscape-why-enerbank-item">
                  <span className="font-weight-bold">
                    Home Improvement Specialty:
                  </span>{" "}
                  Over two decades of experience in the home improvement
                  industry has enabled us to create solutions tailored to the
                  industry. We’ve funded over $13 billion in home improvement
                  projects through products built around your needs.
                </li>
                <li className="landscape-why-enerbank-item">
                  <span className="font-weight-bold">Personal Service:</span> :
                  When you work with EnerBank, you have a dedicated relationship
                  manager who knows your business and needs—ensuring you receive
                  high-quality service every time. We also have a contractor
                  support line available to assist you.
                </li>
                <li className="landscape-why-enerbank-item">
                  <span className="font-weight-bold">
                    Detailed Account Management:
                  </span>{" "}
                  Working with EnerBank means you get access to our online
                  PartnerPortal. Here you can view real-time data and reports to
                  manage your accounts.
                </li>
                <li className="landscape-why-enerbank-item">
                  <span className="font-weight-bold">Marketing:</span> We
                  provide hardscape contractors with marketing tools and
                  collateral to help them communicate the financing options and
                  their benefits to their prospects.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light-gray py-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Grow Your Business with Contractor Financing
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <p>
                Offering flexible payment options for your customers can help
                you build a competitive edge in today’s home improvement
                landscape and grow your bottom line. Hardscape contractors can
                take advantage of these benefits by working with EnerBank.
              </p>
            </div>
            <div className="col-12 mt-4">
              <p className="text-center">
                <Link to="/sign-up/" className="btn btn-primary">
                  Sign up for a loan program today!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HardscapeLandscapePage
